@import url('https://fonts.googleapis.com/css?family=Caveat');
@import url('https://fonts.googleapis.com/css?family=Xanh+Mono');
@import url('https://fonts.googleapis.com/css?family=Itim');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Itim', cursive;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow: hidden;
    background: black;
}

::-webkit-scrollbar {
    display: none;
}

.arial {
    font-family: Arial, serif;
}

.handwriting {
    font-family: 'Itim', cursive;
    text-transform: uppercase;
}

.monospace {
    font-family: 'Xanh Mono', monospace;
}

.photogradient {
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(66deg, rgba(0, 0, 0, 0.1110819327731093) 0%, rgba(255, 255, 255, 0.38279061624649857) 100%);
    background: -webkit-linear-gradient(66deg, rgba(0, 0, 0, 0.1110819327731093) 0%, rgba(255, 255, 255, 0.38279061624649857) 100%);
    background: linear-gradient(66deg, rgba(0, 0, 0, 0.1110819327731093) 0%, rgba(255, 255, 255, 0.38279061624649857) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#ffffff", GradientType=1);
}

.shadow-inner-4xl {
    box-shadow: inset 5px 5px 30px -10px rgba(0, 0, 0, 1);
}

.postit {
    background: linear-gradient(150deg, #efec88 0%, #fefabc 100%);
    border-bottom-right-radius: 60px 5px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Itim', cursive;
}

.postit::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.025);
    position: absolute;
    width: 100%;
    left: 0px;
    top: 0;
    height: 40px;
    z-index: -1;
}

.postit::after {
    content: "";
    position: absolute;
    bottom: -7px;
    right: -4px;
    width: 100%;
    height: 150px;
    background-image: linear-gradient(173deg, rgba(0, 0, 0, 0) 92%,
    rgba(0, 0, 0, 0.1) 100%);
    transform: rotate(6deg);
    z-index: -1;
    filter: blur(3px);
    transform-origin: right bottom;

}
